

.contenedor {
  width: 100%;
  /*      padding-right: 7.5px; 
     padding-left: 7.5px;  */
  margin-right: auto;
  margin-left: auto;
}
.baner {
  width: 100%;
  height: 100vh;
  position: relative;
}

.baner-content {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #0d7dbe5d; /* fallback for old browsers */

  position: absolute;
  top: 0;
}
.boder {
  padding: 0 20px;
  box-shadow: 0px 0px 3px #4e88d4;
  position: relative;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 25%;
  pointer-events: auto;
  background-clip: padding-box;
  border: solid #e2e9eb8a 3px;
  outline: 0;
  top: 10%;

  /* background: rgba(31, 103, 170, 0.589); */
  background: #1a3642ad; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2c5364b9,
    #203a43b9,
    #0f2027b0
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #376e869d,
    #203a43af,
    #174255ab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: #fff;
  margin: auto auto auto 65%;
  max-height: 59vh;
  -webkit-box-shadow: 0px 10px 45px 22px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 10px 45px 22px rgba(0, 0, 0, 0.3);
  transition: all 600ms ease;
  position: relative;
  border-radius: 1.5%;
}

.contenedor-label {
  margin-right: -12%;
  padding: 0;
}

.contenedor-label label {
  font-size: 30px;
  color: rgb(0, 64, 124);
  text-shadow: rgba(255, 255, 255, 0.885) 0.1em 0.1em;
  font-family: "omegle";
  font-weight: 700;
  right: -10%;
  margin-top: 1%;
}

.titulo-header {
  font-size: 30px;
  
  color: rgb(255, 255, 255);
  text-shadow: rgba(113, 113, 113, 0.885) 0.1em 0.1em;
  font-family: "omegle";
  font-weight: 700;
  right: -10%;
  margin-top: 1%;
}

.boton {
  background: #e0eafc; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #cfdef3,
    #e0eafc
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #cfdef3,
    #e0eafc
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: #639ae1;
  width: 60%;
  border: none;
  height: 30px;
  margin-top: 15%;
  border-radius: 2px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

.boton:hover {
  background: #00d2ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3a7bd5,
    #00d2ff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3a7bd5,
    #00d2ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fdfdfd;
  transform: translateY(1%);
  -webkit-box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
}

.municipio {
  margin: -3% 0 0 0;
  display: "block";
  color: rgb(254, 254, 255);
  text-shadow: rgb(255, 255, 255) 0.1em 0.1em 0.2em;
  text-transform: uppercase;
  font-family: "dunk";
  text-align: center;
  font-size: 25px;
}

.uppercase {
  text-transform: uppercase;
}

.boder2 {
  padding: 0 20px;
  position: relative;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: 0.4rem;
  outline: 0;
  color: #fff;
  top: 50px;
}
.user-img {
  margin-top: -60px;
  margin-bottom: 35px;
  background-color: rgb(255, 255, 255);
  padding: 5% 0;
  width: 35%;
  margin-left: 32%;
  border-radius: 100%;
  overflow: hidden;
  -webkit-box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.hidden{
  visibility: collapse;
}

.visible{
  visibility: visible;
}

.user-img2 {
  margin-top: -20px;
  background-color: rgb(255, 255, 255);
  width: 25%;
  height: 40%;
  /*  margin-left: 35%; */
  margin: auto;
  border-radius: 100%;
  overflow: hidden;
  -webkit-box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
  position: relative;
}
.user-img img {
  width: 90px;
  height: 80px;
}
.user-img2 img {
  width: 90px;
  height: 80px;
  margin: auto;
}

.button2 {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-user-select: none; /* for button */
  -webkit-appearance: button; /* for input */
  -moz-user-select: none;
  -ms-user-select: none;
  width: auto;
}
.forgot {
  padding: 5px 0;
}

.form-text{
  color: #eb6666;
}

.forgot a {
  color: rgb(255, 255, 255);
}

.boder .input2 {
  width: 95%;
  height: 40px;

  margin: 10% 0 0 0;
  border: none;
  background-color: transparent;
  background: transparent;
  border-bottom: 1px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.inpt {
  width: 95%;
  height: 40px;
  margin: 1%;
  border: none;
  background-color: transparent;
  background: transparent;
  border-bottom: 1px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.slide6 {
  background-color: rgba(0, 120, 210, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
}

/*  .baner-content a{
    text-decoration: none;
    color: #fff;
    padding: 9px 20px;
    border: 1px solid #fff;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
}
.baner-content a:hover{
    background-color: #fff;
    color: #333;

} */
.holalogo{
  background-image: url(./imagenes/logo.png);
}

/* background-image: url('../public/imagenes/slider1.png');  */

@keyframes baner {
  0% {
    background-image: url("http://localhost:2300/imagen?img=slider1.png");
  }
  25% {
    background-image: url("http://localhost:2300/imagen?img=slider1.png");
  }
  26% {
    background-image: url("http://localhost:2300/imagen?img=slider2.png");
  }
  50% {
    background-image: url("http://localhost:2300/imagen?img=slider2.png");
  }
  51% {
    background-image: url("http://localhost:2300/imagen?img=slider3.png");
  }
  75% {
    background-image: url("http://localhost:2300/imagen?img=slider3.png");
  }
  76% {
    background-image: url("http://localhost:2300/imagen?img=slider4.png");
  }
  100% {
    background-image: url("http://localhost:2300/imagen?img=slider4.png");
  }
}
.fileContainer {
  overflow: hidden;
  position: relative;
  background-color: #941b80;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  margin: 0 auto 0;
  min-height: 15px;
  padding: 10px;
  text-align: center;
  width: 400px;
  margin-bottom: 10px;
}
.fileContainer [type="file"] {
  border: 10000px solid transparent;
  cursor: pointer;
  font-size: 10000px;
  margin: 0;
  opacity: 0;
  outline: 0 none;
  padding: 0;
  position: absolute;
  right: -1000px;
  top: -1000px;
}



.footer {
  font-size: 15px;
  padding: 10px 0;
  font-weight: bold;
}

.sistema {
  position: absolute;
  margin-top: 14%;

  margin-left: 10%;
  text-align: center;
}

.integral {
  right: -10%;
  padding: 0;
  margin: 0;
}

.sistema h1 {
  /*  */

  margin: -4% 0 0 0;
  margin-left: -10%;
  font-size: 120px;
  /* 
   */
  overflow: hidden;
  white-space: nowrap;
  animation: typing 0.9s steps(38) 1s 1 normal both, blink 1s steps(1) infinite;
}

.sistema h1 .span1 {
  font-size: 130px;
  font-family: "sistema";
  text-shadow: rgb(255, 255, 255) 0.1em 0.1em 0.2em;
  color: #e26015;
}

.sistema h1 .span2 {
  font-size: 100px;

  font-family: "sistema";
  text-shadow: rgb(255, 255, 255) 0.1em 0.1em 0.2em;
  color: rgb(0, 64, 124);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 110%;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.logoSygre {
  width: 250px;
  height: 100px;
  position: relative;
  margin: 0;
  left: -10%;
}



.bg-css-1 {
  background: #11998e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #38ef7d,
    #11998e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #38ef7d,
    #22daca
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.azulGradiant2 {
  background: linear-gradient(to bottom, rgba(32, 115, 145, 1) 0%, rgba(0, 88, 129, 1) 1%, rgba(5, 81, 130, 1) 2%, rgba(11, 81, 132, 1) 3%, rgba(8, 82, 129, 1) 4%, rgba(11, 79, 128, 1) 8%, rgba(8, 81, 126, 1) 10%, rgba(7, 80, 131, 1) 11%, rgba(7, 80, 131, 1) 12%, rgba(9, 79, 128, 1) 14%, rgba(8, 78, 129, 1) 23%, rgba(5, 75, 126, 1) 29%, rgba(6, 73, 126, 1) 47%, rgba(8, 71, 122, 1) 51%, rgba(2, 72, 131, 1) 55%, rgba(5, 71, 129, 1) 60%, rgba(1, 69, 130, 1) 65%, rgba(4, 68, 129, 1) 69%, rgba(0, 66, 127, 1) 84%, rgba(3, 65, 124, 1) 86%, rgba(0, 67, 126, 1) 89%, rgba(4, 64, 124, 1) 92%, rgba(2, 62, 124, 1) 94%, rgba(4, 65, 130, 1) 95%, rgba(0, 61, 126, 1) 96%, rgba(4, 64, 127, 1) 97%, rgba(7, 62, 119, 1) 98%, rgba(10, 54, 101, 1) 99%, rgba(34, 63, 95, 1) 100%);
}

/* clases estandares de estilos  */

.bg-input{
  background-color: #fff;
  color: #131313;
}

.sombras {
  -webkit-box-shadow: 0px 12px 16px 13px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 12px 16px 13px rgba(0, 0, 0, 0.3);
}

.negrita {
  font-weight: bold;
}

.title-table {
  color: rgb(8, 65, 102);
  font-size: 40px;
  font-weight: bold;
  font-family: Helvetica;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
    0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
}

.header-modal{
  height: 40px;
  align-items: center;
}

.text-success2{
  color:#005121;
}

.text-danger2{
  color:#ff0000;
}

.mayuscula{
  text-transform: uppercase;
}


.btn-success2 {
  color: #fff;
  background-color: #005121;
}
.btn-success2.focus,
.btn-success2:focus,
.btn-success2:hover {
  color: #fff;
  background-color: #0e5a2d;
}
.btn-check:active + .btn-success2,
.btn-check:checked + .btn-success2,
.btn-success2.active,
.btn-success2:active,
.show > .btn-success2.dropdown-toggle {
  color: #fff;
  background-color: #0e5028;
}
.btn-check:active + .btn-success2:focus,
.btn-check:checked + .btn-success2:focus,
.btn-success2.active:focus,
.btn-success2:active:focus,
.show > .btn-success2.dropdown-toggle:focus {
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2),
    0 4px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}
.btn-success2.disabled,
.btn-success2:disabled {
  color: #fff;
  background-color: #005121;
}

input:focus {
  outline:2px solid #02304c;
}

.border-table{
  border: solid rgba(5, 81, 130, 1);
}


.bg-card-warnir {
  color: #fff;
  background-color: #ffae00;
}
.bg-card-warnir.focus,
.bg-card-warnir:focus{
  color: #fff;
  background-color: #ffae00;
}

.btn-check:active + .bg-card-warnir:focus,
.btn-check:checked + .bg-card-warnir:focus,
.bg-card-warnir.active:focus,
.bg-card-warnir:active:focus,
.show > .bg-card-warnir.dropdown-toggle:focus {
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2),
    0 4px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}
.bg-card-warnir.disabled,
.bg-card-warnir:disabled {
  color: #fff;
  background-color: #ffae00;
}


.bg-card-success {
  color: #fff;
  background-color: #28a745;
}
.bg-card-success.focus,
.bg-card-success:focus{
  color: #fff;
  background-color: #28a745;
}

.btn-check:active + .bg-card-success:focus,
.btn-check:checked + .bg-card-success:focus,
.bg-card-success.active:focus,
.bg-card-success:active:focus,
.show > .bg-card-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2),
    0 4px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}
.bg-card-success.disabled,
.bg-card-success:disabled {
  color: #fff;
  background-color: #28a745;
}




.bg-card-info {
  color: #fff;
  background-color: #17a2b8;
}
.bg-card-info.focus,
.bg-card-info:focus{
  color: #fff;
  background-color: #17a2b8;
}

.btn-check:active + .bg-card-info:focus,
.btn-check:checked + .bg-card-info:focus,
.bg-card-info.active:focus,
.bg-card-info:active:focus,
.show > .bg-card-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2),
    0 4px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}
.bg-card-info.disabled,
.bg-card-info:disabled {
  color: #fff;
  background-color: #17a2b8;
}
/* ***************************** */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.tooltip .tiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 6px 0;
  position: absolute;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.tooltip .tiptext::after {
  content: "";
  position: absolute;
  border-width: 5px;
  border-style: solid;
}
.tooltip:hover .tiptext {
  visibility: visible;
}

.tooltip.top .tiptext{
  margin-left: -60px;
  bottom: 150%;
  left: 50%;
}
.tooltip.top .tiptext::after{
  margin-left: -5px;
  top: 100%;
  left: 50%;
  border-color: #2E2E2E transparent transparent transparent;
}

#intro {
  background-image: url(https://mdbootstrap.com/img/new/fluid/city/008.jpg);
  height: 95vh;
}

/* Height for devices larger than 576px */
@media (min-width: 992px) {
  #intro {
    margin-top: -58.59px;
  }
}

.navbar2 .nav-link {
  color: #fff !important;
}

:root {
  --main-bg-color: #009d63;
  --main-text-color: #009d63;
  --bluez-text-color: #1793dd;
  --bluesis-bg-color: #034782;
  --amaril-text-color: #bae10c;
  --redlater-text-color: #fa4626;
  --lleyow-text-color: #b05cb8;
  --amanecer-text-color: #15765231;
  --second-text-color: #1d0345;
  --bluex-bg-color: #cfe2ff;
  --bluez-bg-color: #1793dd;
  --amaril-bg-color: #bae10c;
  --zfox-bg-color: #289599;
  --redlater-bg-color: #fa4626;
  --lleyow-bg-color: #b05cb8;
  --otrodia-bg-color: #4edaa782;
  --amanecer-bg-color: #15765231;
  --llanero-bg-color: #fd7e14;
  --coloriconorinfo-bg-color: #008080;
  --diagris-bg-color: #6a737b;

}


.fusionpro{
  background: rgba(40,167,69,1);
  background: -moz-linear-gradient(left, rgba(40,167,69,1) 0%, rgba(40,167,69,1) 0%, rgba(39,167,74,0.93) 4%, rgba(23,162,184,0.93) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(40,167,69,1)), color-stop(0%, rgba(40,167,69,1)), color-stop(4%, rgba(39,167,74,0.93)), color-stop(100%, rgba(23,162,184,0.93)));
  background: -webkit-linear-gradient(left, rgba(40,167,69,1) 0%, rgba(40,167,69,1) 0%, rgba(39,167,74,0.93) 4%, rgba(23,162,184,0.93) 100%);
  background: -o-linear-gradient(left, rgba(40,167,69,1) 0%, rgba(40,167,69,1) 0%, rgba(39,167,74,0.93) 4%, rgba(23,162,184,0.93) 100%);
  background: -ms-linear-gradient(left, rgba(40,167,69,1) 0%, rgba(40,167,69,1) 0%, rgba(39,167,74,0.93) 4%, rgba(23,162,184,0.93) 100%);
  background: linear-gradient(to right, rgba(40,167,69,1) 0%, rgba(40,167,69,1) 0%, rgba(39,167,74,0.93) 4%, rgba(23,162,184,0.93) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28a745', endColorstr='#17a2b8', GradientType=1 );
  color: #fff;
  }

.amanecergris{
  background:linear-gradient(top, #90abb8, white);
  background:-webkit-linear-gradient(top, #90abb8, white);
  background:-moz-linear-gradient(top, #90abb8, white);
  background:-o-linear-gradient(top, #90abb8, white);
  background:-ms-linear-gradient(top, #90abb8, white);
  }

.amanecerfooter{
  background:linear-gradient(top, white, white);
  background:-webkit-linear-gradient(top, white, white);
  background:-moz-linear-gradient(top, white, white);
  background:-o-linear-gradient(top, white, white);
  background:-ms-linear-gradient(top, white, white);
}

.azulGradiant {
  background: linear-gradient(to bottom, rgba(32, 115, 145, 1) 0%, rgba(0, 88, 129, 1) 1%, rgba(5, 81, 130, 1) 2%, rgba(11, 81, 132, 1) 3%, rgba(8, 82, 129, 1) 4%, rgba(11, 79, 128, 1) 8%, rgba(8, 81, 126, 1) 10%, rgba(7, 80, 131, 1) 11%, rgba(7, 80, 131, 1) 12%, rgba(9, 79, 128, 1) 14%, rgba(8, 78, 129, 1) 23%, rgba(5, 75, 126, 1) 29%, rgba(6, 73, 126, 1) 47%, rgba(8, 71, 122, 1) 51%, rgba(2, 72, 131, 1) 55%, rgba(5, 71, 129, 1) 60%, rgba(1, 69, 130, 1) 65%, rgba(4, 68, 129, 1) 69%, rgba(0, 66, 127, 1) 84%, rgba(3, 65, 124, 1) 86%, rgba(0, 67, 126, 1) 89%, rgba(4, 64, 124, 1) 92%, rgba(2, 62, 124, 1) 94%, rgba(4, 65, 130, 1) 95%, rgba(0, 61, 126, 1) 96%, rgba(4, 64, 127, 1) 97%, rgba(7, 62, 119, 1) 98%, rgba(10, 54, 101, 1) 99%, rgba(34, 63, 95, 1) 100%);
 }


.fusionorang{
  background: rgba(167,11,11,1);
  background: -moz-linear-gradient(left, rgba(219,119,25) 0%, rgba(219,119,25) 0%, rgba(219,119,25,0.93) 4%, rgba(100,10,10,0.93) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(219,167,69,1)), color-stop(0%, rgba(219,167,69,1)), color-stop(4%, rgba(219,119,25,0.93)), color-stop(100%, rgba(100,10,10,0.93)));
  background: -webkit-linear-gradient(left, rgba(219,167,69,1) 0%, rgba(219,167,69,1) 0%, rgba(219,119,25,0.93) 4%, rgba(100,10,10,0.93) 100%);
  background: -o-linear-gradient(left, rgba(219,167,69,1) 0%, rgba(219,167,69,1) 0%, rgba(219,119,25,0.93) 4%, rgba(100,10,10,0.93) 100%);
  background: -ms-linear-gradient(left, rgba(219,167,69,1) 0%, rgba(219,167,69,1) 0%, rgba(219,119,25,0.93) 4%, rgba(100,10,10,0.93) 100%);
  background: linear-gradient(to right, rgba(219,167,69,1) 0%, rgba(219,167,69,1) 0%, rgba(219,119,25,0.93) 4%, rgba(100,10,10,0.93) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f0222', endColorstr='#84a896', GradientType=1 );
  /* color: #fff; */
  }

  .fusionred{
      background: rgba(167,11,11,1);
      background: -moz-linear-gradient(left, rgba(167,11,25) 0%, rgba(167,11,25) 0%, rgba(167,11,25,0.93) 4%, rgba(100,10,10,0.93) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(173, 22, 42, 1)), color-stop(0%, rgba(173, 22, 42, 1)), color-stop(4%, rgba(173, 22, 42,0.93)), color-stop(100%, rgba(100,10,10,0.93)));
      background: -webkit-linear-gradient(left, rgb(173, 22, 42, 1) 0%, rgba(173, 22, 42, 1) 0%, rgba(167,11,25,0.93) 4%, rgba(100,10,10,0.93) 100%);
      background: -o-linear-gradient(left, rgba(173, 22, 42, 1) 0%, rgba(173, 22, 42, 1) 0%, rgba(167,11,25,0.93) 4%, rgba(100,10,10,0.93) 100%);
      background: -ms-linear-gradient(left, rgba(173, 22, 42, 1) 0%, rgba(173, 22, 42, 1) 0%, rgba(167,11,25,0.93) 4%, rgba(100,10,10,0.93) 100%);
      background: linear-gradient(to right, rgba(173, 22, 42, 1) 0%, rgba(173, 22, 42, 1) 0%, rgba(167,11,25,0.93) 4%, rgba(100,10,10,0.93) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f0222', endColorstr='#84a896', GradientType=1 );
      color: #fff;
      }


.bluex-bg{
  background-color: var(--bluex-bg-color);
}

.bluesis-bg{
  background-color: var(--bluesis-bg-color);
}

.bluez-bg{
  background-color: var(--bluez-bg-color);
}

.diagris-bg{
  background-color: var(--diagris-bg-color);
}

.info-bg{
  background-color: var(--coloriconorinfo-bg-color);
}

.amaril-bg{
  background-color: var(--amaril-bg-color);
}

.zfox-bg{
  background-color: var(--zfox-bg-color);
}

.redlater-bg{
  background-color: var(--redlater-bg-color);
}

.llanero-bg{
  background-color: var(--llanero-bg-color);
}

.lleyow-bg{
  background-color: var(--lleyow-bg-color);
}

.otrodia-bg{
  background-color: var(--otrodia-bg-color);
}

.amanecer-bg{
  background-color: var(--amanecer-bg-color);
}

.bluez-text {
  color: var(--bluez-text-color);
}

.amaril-text {
  color: var(--amaril-text-color);
}

.lleyow-text {
  color: var(--lleyow-text-color);
}

.redlater-text {
  color: var(--redlater-text-color);
}

.amanecer-text {
  color: var(--amanecer-text-color);
}

.primary-text {
  color: var(--main-text-color);
}

.second-text {
  color: var(--second-text-color);
}

.primary-bg {
  background-color: var(--main-bg-color);
}

.secondary-bg {
  background-color: var(--second-bg-color);
}

.rounded-full {
  border-radius: 100%;
}


#pruetolt{
  width: auto;
  margin-top: -2rem;
  border-radius: 5px;
  text-align: center;
  height: 20px;
  font-size: 12px;
}

#pruetoltasig{
  width: auto;
  margin-top: -3rem;
  border-radius: 5px;
  text-align: center;
  height: 35px;
  font-size: 12px;
}

#pruetoltasigs{
  width: auto;
  margin-top: -1.9rem;
  border-radius: 5px;
  text-align: center;
  height: 20px;
  font-size: 12px;
}

#pruetoltasigs:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -9px;
  width: 8px;
  height: 8px;
  background: #008080;
  transform: rotate(45deg);
}

#pruetoltmon{
  width: 200px;
  margin-top: -2rem;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
}

#pruetoltasig:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -9px;
  width: 8px;
  height: 8px;
  background: #008080;
  transform: rotate(45deg);
}

#pruetolt:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -9px;
  width: 8px;
  height: 8px;
  background: #008080;
  transform: rotate(45deg);
}

#pruetoltmon:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -9px;
  width: 8px;
  height: 8px;
  background: #008080;
  transform: rotate(45deg);
}

/* #tablas{
  overflow: scroll;
} */

#sidebar-wrapper {
  min-height: 95vh;
  /* max-height: 93vh; */
  margin-left: -19rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#swrapper {
  min-height: 95vh;

}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#one {
  max-height: 7vh;
  min-height: 7vh;
  max-width: 100%;
}

#scrol {
  overflow: scroll;
  min-height: 95vh;
  max-height: 95vh;
  max-width: 100%;
}

#gg {
  /* max-height: 7vh; */
  min-height: 5vh;
}

#page-content-wrapper {
  min-width: 100vw;
   max-width: 100%;
  max-height: 100%; 
  min-height: 95vh;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#menu-toggle {
  cursor: pointer;

}

.bloquear {
pointer-events: none;
cursor: default;
}

.desbloquear {
  cursor: pointer;
}

.list-group-item {
  border: none;
  padding: 20px 30px;
}

.list-group-item-active {
  background-color: transparent;
  font-weight: bold;
  border: none;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -16rem;
  }

  .dropdown-menu li {
    position: relative;
    }
    .dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
    }
    .dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
    }
    .dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
    }
   
}

@media (max-width: 1250px) {
  #wrapper.toggled #sidebar-wrapper {
    margin-left: -227px;
  }
}

/* body {
  font-family: arial;
  padding: 100px;
} */

.tooltip-one {
  margin: 0 auto;
}

/* EMPIEZA AQUÍ */

.tooltip-one {
  padding: 18px 32px;
  background: #fff;
  position: absolute;
  min-width: 150px;
  max-width: 180px;
  margin-top: -5rem;
  border-radius: 5px;
  text-align: center;
  filter: drop-shadow(0 3px 5px #ccc);
  line-height: 1.5;
}

.tooltip-one:after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 50%;
  margin-left: -9px;
  width: 18px;
  height: 18px;
  background: white;
  transform: rotate(45deg);
}

.logo-sidebar{
  background-image: url('./imagenes/logo.png');
  background-size: 100% 100%;
}

/* .logo-login{
  background-image: url('./imagenes/logo.png');
  background-size: 100% 100%;
} */




#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 220px;
}
.navbar2{
  background: #0F2027;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}

.danger1{
  background-color: #dc2637;
}

.danger2{

}

.danger3{

}
#sidebar-wrapper {
  z-index: 1000;
  left: 220px;
  width: 0;
  height: 100%;
  margin-left: -220px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #0F2027;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#sidebar-wrapper::-webkit-scrollbar {
display: none;
}

#wrapper.toggled #sidebar-wrapper {
  width: 236px;
}

#page-content-wrapper {
  width: 100%;
 
}

#wrapper.toggled #page-content-wrapper {
 
  margin-right: -220px;
}

/*-------------------------------*/
/*     Sidebar nav styles        */
/*-------------------------------*/
.navbar2 {
padding: 0;
}

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 220px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  position: relative; 
  line-height: 20px;
  display: inline-block;
  width: 100%;
}

.sidebar-nav li:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 3px;
  background-color: #1c1c1c;
  -webkit-transition: width .2s ease-in;
    -moz-transition:  width .2s ease-in;
     -ms-transition:  width .2s ease-in;
          transition: width .2s ease-in;

}
.sidebar-nav li:hover{
background: skyblue !important;
border-radius: 10px;
margin-left: 10px;
margin-right: 10px;
}
.sidebar-nav li:hover:before,
.sidebar-nav li.open:hover:before {
  width: 100%;
  -webkit-transition: width .2s ease-in;
    -moz-transition:  width .2s ease-in;
     -ms-transition:  width .2s ease-in;
          transition: width .2s ease-in;

}

.sidebar-nav li a {
  display: block;
  color: #ddd;
  text-decoration: none;
  padding: 10px 15px 10px 30px;    
}

.sidebar-nav li a:hover,
.sidebar-nav li a:active,
.sidebar-nav li a:focus,
.sidebar-nav li.open a:hover,
.sidebar-nav li.open a:active,
.sidebar-nav li.open a:focus{
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
.sidebar-header {
  text-align: center;
  font-size: 20px;
  position: relative;
  width: 100%;
  display: inline-block;
}
.sidebar-brand {
  height: 65px;
  position: relative;
  background:#212531;
  background: linear-gradient(to right bottom, #2f3441 50%, #212531 50%);
 padding-top: 1em;
}
.sidebar-brand a {
  color: #ddd;
}
.sidebar-brand a:hover {
  color: #fff;
  text-decoration: none;
}
.dropdown-header {
  text-align: center;
  font-size: 1em;
  color: #ddd;
  background:#212531;
  background: linear-gradient(to right bottom, #2f3441 50%, #212531 50%);
}
.sidebar-nav .dropdown-menu {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: #222;
  box-shadow: none;
}
.dropdown-menu.show {
  top: 0;
}
/*Fontawesome icons*/
.nav.sidebar-nav li a::before {
  font-family: fontawesome;
  content: "\f12e";
  vertical-align: baseline;
  display: inline-block;
  padding-right: 5px;
}

/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/

.hamburger {
position: fixed;
top: 20px;  
z-index: 999;
display: block;
width: 32px;
height: 32px;
margin-left: 15px;
background: transparent;
border: none;
}
.hamburger:hover,
.hamburger:focus,
.hamburger:active {
outline: none;
}
.hamburger.is-closed:before {
content: '';
display: block;
width: 100px;
font-size: 14px;
color: #fff;
line-height: 32px;
text-align: center;
opacity: 0;
-webkit-transform: translate3d(0,0,0);
-webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed:hover:before {
opacity: 1;
display: block;
-webkit-transform: translate3d(-100px,0,0);
-webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
position: absolute;
left: 0;
height: 4px;
width: 100%;
}
.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom {
background-color: #1a1a1a;
}
.hamburger.is-closed .hamb-top { 
top: 5px; 
-webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed .hamb-middle {
top: 50%;
margin-top: -2px;
}
.hamburger.is-closed .hamb-bottom {
bottom: 5px;  
-webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed:hover .hamb-top {
top: 0;
-webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed:hover .hamb-bottom {
bottom: 0;
-webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
background-color: #1a1a1a;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
top: 50%;
margin-top: -2px;  
}
.hamburger.is-open .hamb-top { 
-webkit-transform: rotate(45deg);
-webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
}
.hamburger.is-open .hamb-middle { display: none; }
.hamburger.is-open .hamb-bottom {
-webkit-transform: rotate(-45deg);
-webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
}
.hamburger.is-open:before {
content: '';
display: block;
width: 100px;
font-size: 14px;
color: #fff;
line-height: 32px;
text-align: center;
opacity: 0;
-webkit-transform: translate3d(0,0,0);
-webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open:hover:before {
opacity: 1;
display: block;
-webkit-transform: translate3d(-100px,0,0);
-webkit-transition: all .35s ease-in-out;
}

.login {
  min-height: 120vh;
}

.bg-image {
 /* background-image: url('https://source.unsplash.com/WEQbe2jBg40/600x1200');*/
  background-size: cover;
  background-position: center;
}

.login-heading {
  font-weight: 300;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
}



.slider {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.slides {
  display: flex;
  transition: transform 1s ease-in-out;
}

.slide {
  width: 100%;
  height: 100%;
  background-size: cover;
}

#slide1 { background-image: url('https://previews.123rf.com/images/artshock/artshock1209/artshock120900045/15221647-imag-of-heart-in-the-blue-sky-against-a-background-of-white-clouds.jpg'); }
#slide2 { background-image: url('https://previews.123rf.com/images/artshock/artshock1209/artshock120900045/15221647-imag-of-heart-in-the-blue-sky-against-a-background-of-white-clouds.jpg'); }
#slide3 { background-image: url('https://previews.123rf.com/images/artshock/artshock1209/artshock120900045/15221647-imag-of-heart-in-the-blue-sky-against-a-background-of-white-clouds.jpg'); }
/* Agrega más estilos según sea necesario */

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + .slider .slides {
  transform: translateX(-100%);
}

input[type="radio"]#slide1:checked + .slider .slides { transform: translateX(0%); }
input[type="radio"]#slide2:checked + .slider .slides { transform: translateX(-100%); }
input[type="radio"]#slide3:checked + .slider .slides { transform: translateX(-200%); }
/* Agrega más reglas para slides adicionales si es necesario */

/* Las imágenes no van a salir del margen de la pantalla */
div#slider { overflow: hidden; } 

/* Establece el tamaño de las imágenes */
div#slider figure img {
  width: 20%; /* Ancho del 20% del contenedor */
  float: left;
  height: 100vh; /* Altura igual al 100% de la altura de la pantalla */
  max-height: 800px; /* Altura máxima de 800px */
}

div#slider figure { 
  position: relative;
  width: 500%;
  margin: 0;
  left: 0;
  text-align: left;
  font-size: 0;
  animation: 20s slidy infinite; /* El movimiento se va a mantener de forma indefinida -infinito-*/
}

/* Cambios para dispositivos móviles */
@media screen and (max-width: 600px) {
  div#slider figure img {
    height: 50vh; /* Reducir la altura al 50% de la altura de la pantalla */
    max-height: 400px; /* Altura máxima de 400px */
  }
}

/*esta parte del código define el movimiento de las imágenes a la izquierda*/
@keyframes slidy {
0% { left: 0%; }
20% { left: 0%; }
40% { left: -100%; }
60% { left: -100%; }
80% { left: -200%; }
100% { left: -200%; }
}

.bg-servial{
  background-color: #ce2424;
}

.logo-navbar{
  max-height: 60px;
}

.hidden_home {
  margin-left: -100%;
}

.bg-servial{
  background-color: #ce2424;
}

.text-servial{
  color: #ce2424;
}

.hidden_home {
  margin-left: -100%;
}

.hidden_img{
  margin-right: -100%;
}


.btn-wsp{
  position:fixed;
  width:60px;
  height:60px;
  line-height: 63px;
  bottom:25px;
  right:25px;
  background:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:35px;
  box-shadow: 0px 1px 10px rgba(0,0,0,0.3);
  z-index:100;
  transition: all 300ms ease;
  bottom:100px;
}
.btn-wsp:hover{
  background: #20ba5a;

}



@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px ));
            transform: translateX(calc(-250px * 2));
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-750px * 2));
            transform: translateX(calc(-750px * 2));
  }
}


.slider2 {
  background:#ce1f2e;
  /*box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);*/
  height: 50px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 3px 0;
}



.slider2 .slide-track {
 
  -webkit-animation: scroll 20s linear infinite;
          animation: scroll 20s linear infinite;
  display: flex;
  width: 100%;
}
.slider2 .slide {
  height: 30px;
  width: 700px;
  color: #d4edda;
  margin-top: 10px;
  margin-right:-600px ;
  font-weight: bold;
}

/* line 34, src/assets/scss/theme.scss */
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-transition: .2s ease;
  transition: .2s ease;
  background-color: #008CBA; }

/* line 46, src/assets/scss/theme.scss */
.card {
  border: 0; }

/* line 47, src/assets/scss/theme.scss */
.card-pin:hover .overlay {
  opacity: .5;
  border: 5px solid #f3f3f3;
  -webkit-transition: ease .2s;
  transition: ease .2s;
  background-color: #000000;
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

/* line 55, src/assets/scss/theme.scss */
.more {
  color: white;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
  text-transform: uppercase;
  -webkit-transform: translate(-20%, -20%);
          transform: translate(-20%, -20%);
  -ms-transform: translate(-50%, -50%); }

/* line 66, src/assets/scss/theme.scss */
.card-pin:hover .card-title {
  color: #ffffff;
  margin-top: 10px;
  text-align: center;
  font-size: 1.2em; }

/* line 73, src/assets/scss/theme.scss */
.card-pin:hover .more a {
  text-decoration: none;
  color: #ffffff; }

/* line 78, src/assets/scss/theme.scss */
.card-pin:hover .download a {
  text-decoration: none;
  color: #ffffff; }



  /*css chats*/
  .chat{
    margin-top: auto;
    margin-bottom: auto;
  }
  .card2{
   height: 82vh;
    border-radius: 15px !important;
    background-color: rgba(0,0,0,0.4) !important;
  }
  .contacts_body{
    padding:  0.75rem 0 !important;
   
    white-space: nowrap;
    max-height: 90%;
    overflow-y: scroll;
  }
  .msg_card_body{
    overflow-y: auto;
    min-height: 75%;
    max-height: 75%;

    overflow-y: scroll;
  }
  .card-header{
    border-radius: 15px 15px 0 0 !important;
    border-bottom: 0 !important;
  }
 .card-footer{
  border-radius: 0 0 15px 15px !important;
    border-top: 0 !important;
}
  .container{
    align-content: center;
  }
  .search{
    border-radius: 15px 0 0 15px !important;
    background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color:white !important;
  }
  .search:focus{
       box-shadow:none !important;
         outline:0px !important;
  }
  .type_msg{
    background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color:white !important;
    height: 60px !important;
    overflow-y: auto;
  }
    .type_msg:focus{
       box-shadow:none !important;
         outline:0px !important;
  }
  .attach_btn{
border-radius: 15px 0 0 15px !important;
background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color: white !important;
    cursor: pointer;
  }
  .send_btn{
border-radius: 0 15px 15px 0 !important;
background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color: white !important;
    cursor: pointer;
  }
  .search_btn{
    border-radius: 0 15px 15px 0 !important;
    background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color: white !important;
    cursor: pointer;
  }
  .contacts{
    list-style: none;
    padding: 0;
  }
  .contacts li{
    width: 100% !important;
    padding: 5px 10px;
    margin-bottom: 15px !important;
  }
/* .active{
    background-color: rgba(0,0,0,0.3);
} */
  .user_img{
    font-size: 35px;   

   
  
  }
  .user_img2{
 font-size: 35px;   
  
  }
  .user_img_msg{
    height: 40px;
    width: 40px;
    border:1.5px solid #f5f6fa;
  
  }
.img_cont{
    position: relative;
    height: 40px;
    width: 40px;
}
.img_cont_msg{
    height: 40px;
    width: 40px;
}
.online_icon{
  position: absolute;
  height: 15px;
  width:15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border:1.5px solid white;
}
.offline{
  background-color: #c23616 !important;
}
.user_info{
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.user_info span{
  font-size: 20px;
  color: white;
}
.user_info p{
font-size: 10px;
color: rgba(255,255,255,0.6);
}
.video_cam{
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span{
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.msg_cotainer{
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 25px;
  background-color: #e6faff;
  padding: 10px;
  position: relative;
  min-width: 120px;
  color: #000000;
  font-weight: bold;
}
.msg_cotainer_send{
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 25px;
  background-color: #78e08f;
  padding: 10px;
  position: relative;
  min-width: 120px;
  color: #000000;
  font-weight: bold;

}
.msg_time{
  position: absolute;
  left: 0;
  bottom: -18px;
  color: rgba(255,255,255,0.5);
  font-size: 10px;
}
.msg_time_send{
  position: absolute;
  right:0;
  bottom: -18px;
  color: rgba(255,255,255,0.5);
  font-size: 10px;
}
.msg_head{
  position: relative;
}
#action_menu_btn{
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
.action_menu{
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0,0,0,0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.action_menu ul{
  list-style: none;
  padding: 0;
margin: 0;
}
.action_menu ul li{
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.action_menu ul li i{
  padding-right: 10px;

}
.action_menu ul li:hover{
  cursor: pointer;
  background-color: rgba(0,0,0,0.2);
}
@media(max-width: 576px){
.contacts_card{
  margin-bottom: 15px !important;
}
}

.captcha-container {
  display: flex;
  align-items: center;
}

.captcha-text {
  font-size: 22px;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ced4da;
  border-radius: 10px;
  background-color: #f8f9fa;
  user-select: none;
  background-color: cadetblue;
  color: aliceblue;
  font-weight: bold;
  text-decoration: line-through red 3px;
  height: 50px;
}

.form-control.is-valid {
  border-color: #28a745;
}

.form-control.is-invalid {
  border-color: #dc3545;
}